import React from 'react'
import Header from './Header'
import bg from '../assets/BG.svg'
import { useState } from 'react';

function PlanYourTrip() {


    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedAge, setSelectedAge] = useState('');
    const [selectedadultcount,setSelectedAdultCount] = useState(0);
    const [childrencount,setChildrenCount]= useState(0);
    const [roomcount, setRoomCount]= useState(0);
   const [bgimage, setBgImage] = useState(bg)
   const [travelOption, setTravelOption] = useState('');





    const handleChange = (event) => {
        const age = event.target.value;
        setSelectedAge(age);
        console.log(age);
      };
  
      const increaseAdult = ()=>{
        setSelectedAdultCount(selectedadultcount+1)
      }
      const decreaseAdult = ()=>{
        if(selectedadultcount >=1){
          setSelectedAdultCount(selectedadultcount-1)
        }
        
      }
  
      const increasechildrencount = ()=>{
        setChildrenCount(childrencount+1)
      }
      const decreasechildrencount = ()=>{
        if(childrencount >=1){
          setChildrenCount(childrencount-1)
        }
        
      }
  
      const increaseroomcount = ()=>{
        setRoomCount(roomcount+1)
      }
      const decreaseroomcount = ()=>{
        if(roomcount >=1){
          setRoomCount(roomcount-1)
        }
        
      }
  
  
      const handleBookingOptionChange = (event) => {
        const selectedValue = event.target.value;
        setTravelOption(selectedValue);
        console.log('Selected option:', selectedValue);
      };
  
      const handleBooking = ()=>{
       
        console.log('children count',childrencount);
        console.log('Adult count',selectedadultcount);
        console.log('Adult count',selectedadultcount); 
        console.log('Room count', roomcount);
        console.log('age',selectedAge)
  
      }
















  return (
    <div className="h-screen bg-cover bg-center" style={{ backgroundImage: `url(${bgimage})` }}>
     
      
      {/* main div */}
  
               
               {/* number of travelers section */}
<div className="flex justify-center items-start px-5">
        <div className="bg-white lg:p-10 p-6 rounded-lg w-full shadow-lg md:w-[80%]">
          <p className='text-[#11A59B] font-bold text-xl font-poppins pb-4'>Choose No of Travellers</p>

          <div className='bg-[#11A59B] p-4 rounded-t-2xl py-5'>
  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Adults</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseAdult} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{selectedadultcount}</div>
      <div>
        <button onClick={increaseAdult} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>

  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Children (0 to 14 years)</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreasechildrencount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{childrencount}</div>
      <div>
        <button onClick={increasechildrencount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>

  

  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Age - Child 1</div>
    <div className='grid grid-cols-1'>
      <select className='border rounded p-2 w-full font-thin text-blue-500 font-poppins' onChange={handleChange} value={selectedAge}>
        <option className='text-center' value="" disabled>Age</option>
        {[...Array(15).keys()].map(i => {
          const age = i;
          return (
            <option key={age} value={age}>{age}</option>
          );
        })}
      </select>
    </div>
  </div>

  <div className='flex justify-between items-center'>
    <div className='text-white font-poppins'>Rooms</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseroomcount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{roomcount}</div>
      <div>
        <button onClick={increaseroomcount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>
</div>

{/* selected info section */}
<div className='bg-[#E4FFFF] py-8 rounded-b-2xl'>
<div className='grid grid-cols-3 justify-start xl:w-[60%] w-[100%] px-4'>
<div className='text-[#1E75BB] font-bold font-poppins'>Rooms -  {roomcount}</div>
<div className='text-[#1E75BB]  font-poppins'>{selectedadultcount} Adults , </div>
<div className='text-[#1E75BB]  font-poppins'>{childrencount} child</div>
</div>
</div>

     {/* end selected info section */}


{/* price section */}
<div className='bg-[#AEF1EC] my-12 font-poppins p-5 rounded-xl'>
<p className='text-[#006464] font-bold'><b className='text-[#006464] text-4xl'>₹ 25,599</b>/Person</p>
<p className='text-[#006464] py-2'>4 night, Activities & Other Amenities</p>
</div>
{/* end price section */}




{/* travel option section */}
<div>
  <p className='text-center font-poppins pb-6'>
    Would you like to arrange your <br/> travel with this package ?
  </p>

  <div className='grid lg:grid-cols-2 grid-cols-1 items-center mx-auto w-full justify-end text-center bg-[#F5F5F5] font-poppins rounded-lg py-12'>
    <div>
      <label className='text-[#11A59B] font-semibold'>
        <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
          type='radio'
          name='travelOption'
          value='flight'
          checked={travelOption === 'flight'}
          onChange={handleBookingOptionChange}
        /> BOOK WITH FLIGHT
      </label>
    </div>
    <div>
      <label className='text-[#11A59B] font-semibold'>
        <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
          type='radio'
          name='travelOption'
          value='train'
          checked={travelOption === 'train'}
          onChange={handleBookingOptionChange}
        /> BOOK WITH TRAIN
      </label>
    </div>
  </div>

  {/* Center the button */}
  <div className='flex justify-center'>
    <button className='bg-[#11A59B] rounded-full px-20 py-5 -mt-8 text-white text-center' onClick={handleBooking}>
      BOOK PACKAGE
    </button>
  </div>
</div>
{/* travel option section end */}





        </div>
      </div>

{/* end number of travelers section */}
            </div>
    
  )
}

export default PlanYourTrip

// src/components/HomeHeader.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CurrencySelector from './CurrencySelector';
import logo from '../assets/innerlogo.png';
import { currencies } from '../data/currencies';
import menui from '../assets/menuib.png';
import ProfileDrawer from './ProfileDrawer';
import { useSelector } from 'react-redux';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import axios from 'axios';
import { setLogin } from './actions'; // Import the action
import { useDispatch } from 'react-redux';

const HomeHeader = ({ onLoginClick,onLogoutClick }) => {
  const defaultCurrency = currencies.find(currency => currency.value === 'INR');
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [profileDrawer, setProfileDrawer] = useState(false);
  const { token, setToken } = useContext(AuthContext); // Add setToken to the context
  const Login = useSelector((state) => state.Login);
  const dispatch = useDispatch();



console.log('token status', token)

  const { handleLogout } = useContext(AuthContext);


const Logout = async ()=>{
  // onLogoutClick()
  // dispatch(setLogin(false)); // Set Login state to false =====> set this when logout response success
 
  try {
    const response = await axios.post(
      'https://admin.tourismooze.com/api/public/logout',
     
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }
    );
    const data = response.data.status;
    if(response.data.status == false){
      setToken(null)
      onLogoutClick(token)
      handleLogout();
      dispatch(setLogin(false));
    }
    // alert(response.data.message)
  } catch (error) {
    console.error(error);
  }
}





console.log('user-LoggedIn',Login);


  const handleCurrencyChange = (newCurrency) => {
    setSelectedCurrency(newCurrency);
  };

  useEffect(() => {
    console.log("Selected currency:", selectedCurrency);
  }, [selectedCurrency]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className='w-full shadow-md'>
      {profileDrawer && <div className="fixed inset-0 bg-black bg-opacity-50 z-30"></div>}
      {profileDrawer && <ProfileDrawer onClose={() => setProfileDrawer(false)} />}

      {isDrawerOpen && (
        <div className="fixed top-0 h-FULL w-[50%] md:w-[20%] bg-gray-700 z-50 flex flex-col justify-center items-center right-0">
          <div className="p-6 flex">
            <ul>
              <li><Link to="/destination-details" className="mx-4 lg:mx-0 text-md text-white">Destination detail page</Link></li>
              <li><Link to="/package-details" className="mx-4 lg:mx-0 text-md text-red-500">Explore Destinations</Link></li>
              <li><Link to="/packages" className="mx-4 lg:mx-0 text-md text-white">Explore Packages</Link></li>
              <li><Link to="/destinations" className="mx-4 lg:mx-0 text-md text-white">Destinations</Link></li>
              <li><Link to="/profile-drawer" className="mx-4 lg:mx-0 text-md text-white">View profile drawer</Link></li>
              <li><Link to="/plan-your-trip" className="mx-4 lg:mx-0 text-md text-white">Plan your trip</Link></li>
              <li><Link to="/trending-destinations" className="mx-4 lg:mx-0 text-md text-white">trending-destinations</Link></li>
            </ul>
            
            
            
           
            
            
            <span className='text-white' onClick={toggleDrawer}>Close</span>
          </div>
        </div>
      )}

      <div className='flex flex-col lg:flex-row justify-between items-center py-2 px-6 mx-auto xl:w-[80%] w-full'>
        <div className="flex justify-between items-center w-full z-10">
          <div>
            <Link to="/"><img src={logo} alt='logo' className='w-36 lg:w-52' /></Link>
          </div>
          <div>
            <img onClick={() => setProfileDrawer(true)} className='lg:hidden block' src={menui} alt='menuicon' />
          </div>
          <div className="justify-center lg:justify-center items-center w-full hidden lg:flex gap-12 text-white font-semibold px-3">
            <Link to="/destinations" className="mx-4 lg:mx-0 text-md">Explore Destinations</Link>
            <Link to="/packages" className="mx-4 lg:mx-0 text-md">Explore Packages</Link>
          </div>
        </div>

        <div className="flex items-center justify-between lg:justify-end w-full lg:w-[50%] z-10 font-semibold">
          <CurrencySelector
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={handleCurrencyChange}
          />
          <div className='lg:flex items-center ml-6 hidden'>
            <img src={menui} alt='menuicon' onClick={() => setProfileDrawer(true)} />
            {/* <span className="ml-2 text-white font-semibold" onClick={toggleDrawer}>Menu</span> */}
            <span className="ml-2 text-white font-semibold">Menu</span>
          </div>

          <button onClick={Login == true ? Logout : onLoginClick} className='bg-[#11A59B] rounded-2xl text-white font-semibold px-8 ml-6 py-1'>{Login == true ? 'Logout' : 'Login'}</button>

        </div>
      </div>
    </div>
  );
}

export default HomeHeader;

import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [currency, setCurrency] = useState('');

  const handleLogout = () => {
    setToken(null); // Setting token to null
  };

  return (
    <AuthContext.Provider value={{ token,currency, setToken,handleLogout,setCurrency }}>
      {children}
    </AuthContext.Provider>
  );
};



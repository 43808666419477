import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaHeart } from 'react-icons/fa';
import Footer from './Footer';
import { AuthContext } from './AuthContext';
import bg from '../assets/BG.svg';
import { useNavigate } from 'react-router-dom';

import vector from '../assets/Vector.png'
function Packages() {
  const baseUrl = 'https://admin.tourismooze.com/storage/'; // Replace with your actual base URL
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [exploredata,setExploreData]= useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useContext(AuthContext);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [likedStates, setLikedStates] = useState([]);
  const [sideNavOpen, setSideNavOpen] = useState(false);

 const [daysList, setDaysList] = useState([]);
 const [priceList,setPriceList] = useState([]);
 const [selectedDay, setSelectedDay] = useState('');
 const [tourismType,setTourismType] = useState('')
const [selectedSugession,setSelectedSugession] = useState('');
const[selectedPrice,setSelectedPrice] = useState('')

  const handleLike = (index, destination_name, destination_image) => {
    const newLikedStates = [...likedStates];
    newLikedStates[index] = !newLikedStates[index];
    setLikedStates(newLikedStates);
    console.log(`Destination ${destination_name} has been ${newLikedStates[index] ? 'liked' : 'unliked'}.`);
  };

  const handleRemove = (index) => {
    const newLikedStates = [...likedStates];
    newLikedStates[index] = false;
    setLikedStates(newLikedStates);
  };

  const handleProceed = () => {
    const likedDestinations = data.filter((_, i) => likedStates[i]);
    console.log('Proceed with Liked Destinations:', likedDestinations);
    setSideNavOpen(false);
  };







// fetching data for filter
useEffect(() => {
  const fetchFilterData = async () => {
    try {
      const response = await axios.post('https://admin.tourismooze.com/api/public/popular_packages');
      setDaysList(response.data.data.dayslist);
      // Assuming response.data.data.pricelimit is your array of objects
      const priceLimits = response.data.data.pricelimit.map(priceObj => {
        // Convert each object to key-value pair array and return
        return Object.entries(priceObj)[0];
      });
      setPriceList(priceLimits);
      console.log('Price List:', priceLimits);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchFilterData();
}, []);

const handleChange = (event) => {
  setSelectedDay(event.target.value);
};
  // end fetchingdata for filter
const handlePriceListChange = (event)=>{
  setSelectedPrice(event.target.value)
}





  // for trending destinations all
  useEffect(() => {
    fetchDestination();
  }, []);
// end forall trending destinations

const fetchDestination = async () => {
  try {
    const response = await axios.post('https://admin.tourismooze.com/api/public/packages');
    console.log("API Response:", response.data.data.packages.data);
    // console.log("API Responserrrrr:", response.data.data.destinations.data);

    if (response.data.data && response.data.data.packages.data) {
      setData(response.data.data.packages.data);
      // console.log("Full Data", response.data.data.destinations.data);

      response.data.data.packages.data.forEach((destination, index) => {
        // console.log("Heading:", destination.destination_heading);
        // console.log("image:", baseUrl + destination.destination_image);

      });
    } else {
      throw new Error("Trending destinations data not found");
    }
  } catch (error) {
    setError(error);
    console.log(error);
  } finally {
    setLoading(false);
  }
};




const FetchDestination = async () => {
  try {
    // Example payload object
    const payload = {
      days: selectedDay,
      search: selectedSugession,
      category:tourismType,
      budget:selectedPrice,
    };

    // Example headers, adjust as needed
    const headers = {
      'Content-Type': 'application/json',
      // Include any other headers like authorization tokens if required
    };

    // Make the POST request with axios
    const response = await axios.post(`https://admin.tourismooze.com/api/public/packages?page=${countRef.current}`, payload, { headers });

    // Check if data exists in response and has destinations data
    if (response.data.data && response.data.data.packages.data) {
      setData(response.data.data.packages.data);
      
      // Example of iterating through destinations data
      response.data.data.packages.data.forEach((destination, index) => {
        // Process each destination as needed
        console.log(destination);
      });
    } else {
      throw new Error("Trending destinations data not found");
    }
  } catch (error) {
    setError(error);
    console.error('Error fetching destinations:', error);
  } finally {
    setLoading(false);
  }
};


const HandleFilter = ()=>{
  FetchDestination()
}

const HandleClear = async () => {
  try {
    setQuery('');
    setSuggestions([]);
    setTourismType('');
    setSelectedDay('');
    setSelectedSugession('');
    setSelectedPrice('');

    // Refetch destinations data after clearing filters
    await fetchDestination();
  } catch (error) {
    console.error('Error clearing filters and fetching data:', error);
  }
};



// demo
const countRef = useRef(1);
  const [_, forceUpdate] = useState(1);

  const increment = () => {
    countRef.current += 1;
    forceUpdate(n => n + 1); // force a re-render
    FetchDestination()
  };

  const decrement = () => {
    countRef.current -= 1;
    forceUpdate(n => n + 1); // force a re-render
    FetchDestination()
  };

  useEffect(() => {
    console.log('Count updated:', countRef.current);
  }, [countRef.current]);
// end demo







  useEffect(() => {

    const fetchData = async () => {
      try{

        const response = await axios.post('https://admin.tourismooze.com/api/public/home');

        if(response.data.data && response.data.data.categories) {
          setExploreData(response.data.data.categories.slice(0,6));
          // console.log("exploreData", response.data.data.categories);

          response.data.data.categories.forEach((categories, index) => {
            // console.log("name:", categories.name);
            // console.log("imagepath:", baseUrl + categories.image_path);

          });

        }

      }catch{

      }
    }

fetchData();

  },[])


  const fetchSuggestions = async (value) => {
    try {
      const response = await axios.post(
        'https://admin.tourismooze.com/api/public/search-package',
        {
          query: {
            your_field: value
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      const data = response.data;
      console.log('package-sugessions',response.data);
      const suggestions = data.map(item => item.name);
      setSuggestions(suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
    setSelectedSugession(suggestion)
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  





  
  return (
    <div style={{ backgroundImage: `url(${bg})` }} className='bg-cover bg-center bg-no-repeat'>
     
      
      {/* First Section */}
      <div className='w-full rounded-b-[30px] bg-[#AEF1EC] h-auto'>
        <div className='grid lg:grid-cols-2 grid-cols-1 justify-between items-center lg:py-4 py-3 px-6 xl:w-[80%] w-[100%] mx-auto gap-2'>
          <div>
            <h1 className='font-poppins text-[#006464] text-xl lg:text-2xl font-bold lg:text-left text-center'>Pick from Packages</h1>
            <p className='text-[#1E75BB] font-poppins text-sm md:text-md lg:text-left text-center'>Explore places with our budget friendly packages.</p>
          </div>
          
        </div>
      </div>
      {/* End First Section */}

      {/* Body Main Grid Section */}
      <div className='md:flex grid grid-cols-1 xl:w-[80%] w-[100%] mx-auto my-12 gap-4'>
        {/* Grid-1 */}
        <div className='sm:w-[50%] h-auto md:block xl:p-10 lg:p-6 p-3 rounded-xl border-[#c6e4e4] border-[1px] bg-white'>

          <div>
          <h1 className='text-[#11A59B] font-poppins text-md font-semibold mb-5'>Search</h1>
            <input
              type="text"
              value={query}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-3xl"
              placeholder="Search places..."
            />
            {suggestions.length > 0 && (
              <ul className="border border-gray-300 mt-2 rounded shadow-lg">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* Tourism Type Section */}
          <div>
      <h1 className='text-[#11A59B] font-poppins text-md font-semibold my-5'>Select tourism type</h1>
      <div className='gap-2 mx-auto grid grid-cols-3'>
        {exploredata.map((categories, index) => {
          const isSelected = tourismType === categories.id;
          return (
            <div key={index} onClick={()=>setTourismType(categories.id)} className={`cursor-pointer p-2 rounded-md transition duration-300 ease-in-out 
              ${isSelected ? 'bg-[#11A59B] text-white' : 'bg-white text-black'}
              hover:shadow-lg hover:scale-105`}>
                <img className='object-cover rounded-full w-auto mx-auto' src={baseUrl + categories.image_path} alt={categories.name} />
                <p className='text-center font-poppins font-semibold text-[10px] lg:text-[12px] p-1'>{categories.name}</p>
              </div>
          );
        })}
      </div>
    </div>
          {/* End Tourism Type Section */}
          
          <div>
          
    
      <h1 className='text-[#11A59B] font-poppins text-md font-semibold my-5'>Select No of Days</h1>
      <form>
        {daysList.map((day, index) => (
          <div key={index} className='my-1'>
            <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
              type="radio"
              id={`day-${index}`}
              name="days"
              value={day}
              checked={selectedDay === day}
              onChange={handleChange}
            />
            <label className='font-poppins text-[12px] ml-2' htmlFor={`day-${index}`}>{day}</label>
          </div>
        ))}
      </form>
     
     
      <div>
      <h1 className='text-[#11A59B] font-poppins text-md font-semibold my-5'>Select Budget</h1>
      <form>
      
        {priceList.map(([key, value]) => (
          <div key={key} className='my-1'>
                <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
              type="radio"
              id={`value-${value}`}
              name="value"
              checked={selectedPrice === value}
              value= {`${value}`}
              onChange={handlePriceListChange}
            />
            <label className='font-poppins text-[12px] ml-2 font-semibold' htmlFor={`value-${value}`}>{value}</label>

          </div>
          // <li >
          //   {`${key}: ${value}`} {/* Assuming key is the price range and value is the description */}
          // </li>
        ))}
      
      </form>
    </div>
      

    

<div className='h-12'></div>

    <div className='grid grid-cols-2 justify-between items-center w-full'>
    <button className=' bg-[#11A59B] text-[12px] py-2 px-4 text-white rounded-br-2xl font-poppins' onClick={HandleFilter}>Apply filter</button>
    <p className='text-right font-poppins text-[12px] text-[#11A69B] font-bold tracking-wide cursor-pointer hover:text-red-500' onClick={HandleClear}>Clear Filter</p>
    </div>
    

    </div>
          
         
         
        </div>
        {/* End Grid-1 */}

        {/* Grid-2 */}
        <div className='w-full'>
          {/* Image Card Section */}
          
 {/* section design  */}
 {data.map((popular, index) => (
  <div className='mb-4'>
            <div key={index} className=" p-2 my-auto rounded-lg shadow-md border border-[#c6e4e4] bg-[#f5f5f5]">
            <div className='flex gap-4 flex-wrap xl:flex-nowrap items-center'>
            <div className=''>
              <img className='object-cover w-full rounded-lg' src={baseUrl+popular.package_image} alt='gdsvs' />
            </div>
            <div className='p-1 grid w-full mx-auto'>
              
              <h1 className='text-[#2f7a75] xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl text-2xl font-semibold md:w-72 w-full text-center md:text-left'>{popular.name}</h1>
              <span className='text-[#1F75B9] text-md text-center md:text-left'>{popular.short_description}
            </span>
              <ul className="flex flex-wrap w-82 list-disc justify-left ml-5 gap-x-12 text-[#587893] text-md large-bullets">
              {popular.additional_infos.map((info, i) => (
              <li key={i}>{info}</li>
            ))}
            
          </ul>
    
          <div className='grid grid-cols-2 w-full items-center justify-between gap-6 py-2'>
            <span className=' text-left text-[#2f7a75]'><b className='text-[#2f7a75] 2xl:text-2xl text-xl font-poppins'>{popular.price_per_person}</b>/person</span>
            <button onClick={() => navigate('/package-details',{ state: { popular } })} className=' bg-[#11A59B] py-2 font-poppins text-white rounded-br-2xl'>KNOW MORE</button>
          </div>
            </div>
            </div>
          </div>
          </div>
           ))}
        
        {/* end section design */}

        <div className='flex gap-4 items-center bg-white px-6 py-1 border-[#11A59B] border-[1px] rounded-sm shadow-sm w-36 mx-auto'>
        <button className='text-[10px] font-poppins text-[#11A59B] font-semibold tracking-wide cursor-pointer text-center' onClick={decrement} disabled={countRef.current === 1}>Prev</button>
      <p className='text-[10px] font-poppins text-[#11A59B] font-semibold'>-{countRef.current}-</p>
      <button className='text-[10px] font-poppins text-[#11A59B] font-semibold tracking-wide cursor-pointer text-center'  onClick={increment} disabled={data === null}>Next</button>
    </div>





          <div className='md:hidden block px-2 container mx-auto overflow-x-clip'>
         
           
          </div>
          {/* End Image Card Section */}
        </div>
        {/* End Grid-2 */}
      </div>
      {/* End Body Main Section */}

      {/* Footer Section */}
      <Footer />
      {/* End Footer Section */}

      {/* Background Overlay */}
      {sideNavOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setSideNavOpen(false)}
        ></div>
      )}

      
    </div>
  );
}

export default Packages;

import React from 'react'
import Header from './Header'
import Popup from './Popup';
import { useState } from 'react';
import pkgdetailsimg from '../assets/houseboat-alappuzha-backwaters-kerala.jpg'
import calender from '../assets/Calendar.png';
import hotel from '../assets/Hotel.png';
import locationn from '../assets/Location.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useRef } from 'react';
import locationico from '../assets/locationico.png';
import nightico from '../assets/nightico.png';
import Footer from './Footer'
import compico from '../assets/compico.png';
import bg from '../assets/BG.png';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

const baseUrl = 'https://admin.tourismooze.com/storage/'; // Base URL for images
function PackageDetails() {

const location = useLocation();
  const { popular } = location.state;

  const [numberofDays, setNumberOfDays] = useState([]);
  const [packageSlider,setPackageSlider] = useState([])
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);





console.log('selected location from last page ',popular.Pickup_location)


  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedadultcount,setSelectedAdultCount] = useState(0);
  const [childrencount,setChildrenCount]= useState(0);
  const [roomcount, setRoomCount]= useState(0);
 const [bgimage, setBgImage] = useState(bg)
 const [travelOption, setTravelOption] = useState('');
  const handleLoginClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const sliderRef = useRef(null);


  const images = [
    pkgdetailsimg,
    pkgdetailsimg,
    pkgdetailsimg
  ];
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      appendDots: (dots, currentSlide) => (
        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 lg:px-12' style={{ bottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
          <ul className="flex justify-center xl:justify-start space-x-1 lg:m-0">{dots}</ul>
          <div className='text-white flex justify-center xl:justify-end items-center space-x-2 xl:-mt-8 m-2'>
            <div className='backdrop-blur-sm bg-white/30 flex justify-center lg:justify-end items-center space-x-2 px-5 lg:py-2 py-1 rounded-full'>
              <button onClick={() => sliderRef.current.slickPrev()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
                <span>&#x2190;</span> <span>PREV</span>
              </button>
              <span className="text-white">|</span>
              <button onClick={() => sliderRef.current.slickNext()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
                <span>NEXT</span> <span>&#x2192;</span>
              </button>
            </div>
          </div>
        </div>
      ),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };



 // Fetch popular package details
 useEffect(() => {
  const fetchPopularDataDetails = async () => {
    try {
      const response = await axios.post(`https://admin.tourismooze.com/api/public/packages/${popular.id}`, null, {
        headers: {
          'Authorization': 'Bearer your_token_here',
          'Content-Type': 'application/json',
        }
      });
      const popularpackagesdetail = response.data.data.package_detail;
      const daydetails = popularpackagesdetail.days;
      const packageslider = popularpackagesdetail.images;
      setNumberOfDays(daydetails);
      setPackageSlider(packageslider)
    } catch (error) {
      console.error('Error fetching popular packages', error);
    }
  }
  fetchPopularDataDetails();
}, [popular.id]);
// end function for popular packages details




    const handleChange = (event) => {
      const age = event.target.value;
      setSelectedAge(age);
      console.log(age);
    };

    const increaseAdult = ()=>{
      setSelectedAdultCount(selectedadultcount+1)
    }
    const decreaseAdult = ()=>{
      if(selectedadultcount >=1){
        setSelectedAdultCount(selectedadultcount-1)
      }
      
    }

    const increasechildrencount = ()=>{
      setChildrenCount(childrencount+1)
    }
    const decreasechildrencount = ()=>{
      if(childrencount >=1){
        setChildrenCount(childrencount-1)
      }
      
    }

    const increaseroomcount = ()=>{
      setRoomCount(roomcount+1)
    }
    const decreaseroomcount = ()=>{
      if(roomcount >=1){
        setRoomCount(roomcount-1)
      }
      
    }


    const handleBookingOptionChange = (event) => {
      const selectedValue = event.target.value;
      setTravelOption(selectedValue);
      console.log('Selected option:', selectedValue);
    };

    const handleBooking = ()=>{
     
      console.log('children count',childrencount);
      console.log('Adult count',selectedadultcount);
      console.log('Adult count',selectedadultcount); 
      console.log('Room count', roomcount);
      console.log('age',selectedAge)

    }




  


  return (
    <div>
       
        {isPopupVisible && <Popup onClose={handleClosePopup} />}


{/* body section */}
        <div>


<div>
  <h1 className='text-center text-[#006464] font-poppins text-3xl font-bold pt-10'>{popular.name}</h1>
  </div>

<div className='flex justify-center py-5 font-poppins text-black '>

  <div className='flex flex-wrap md:flex-nowrap items-center gap-2 m-2'>
    <div><img src={calender} alt='calender' /></div>
    <div>{popular.number_of_nights} nights</div>
  </div>

  <div className='flex flex-wrap md:flex-nowrap items-center gap-2 m-2'>
    <div><img src={locationn} alt='hotel' /></div>
    <div>{popular.Pickup_location}</div>
  </div>

  <div className='flex flex-wrap md:flex-nowrap items-center gap-2 m-2'>
    <div><img src={hotel} alt='location' /></div>
    <div>{popular.number_of_nights} Resorts</div>
  </div>

</div>
















<div>
  <div className="w-[90%] mx-auto ">
    <Slider {...settings} ref={sliderRef}>
      {packageSlider.map((image, index) => (
        <div key={index} className='md:h-96 h-auto relative rounded-xl'>
          <img src={baseUrl+image.image} alt={`Slide ${index + 1}`} className="w-full rounded-xl bg-center object-contain" />
          {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
        </div>
      ))}
    </Slider>
  </div>
</div>



<div className="h-auto bg-cover bg-center xl:-mt-56 lg:-mt-32 md:-mt-18 -mt-16 rounded-t-3xl" style={{ backgroundImage: `url(${bgimage})` }}>





<div className='mx-auto flex flex-wrap lg:flex-nowrap w-[100%] md:w-[80%] lg:w-[90%] justify-center md:pt-40 lg:pt-72 pt-32 overflow-x-clip'>
  
{/* timeline section */}
<div className="mx-auto px-4 py-8 lg:max-w-[60%] max-w-[100%]">
  <p className="font-bold text-lg mb-2 text-white font-poppins">Day By Day Plan </p>

  {numberofDays.map((day, index) => (
    <div key={index} className="relative mb-1 flex items-start">
      {/* Dotted Line */}
      <div className="absolute h-full border-l-4 border-dotted border-white left-6"></div>
      {/* end Dotted Line */}

      {/* Big Dot */}
      <div className="w-10 flex-none">
        <div className="bg-[#006464] rounded-full w-5 h-5 border-4 border-white relative left-4 mt-4"></div>
      </div>
      {/* end Big Dot */}

      {/* content section */}
      <div className="ml-2 flex-1">
        <p className='text-white font-poppins lg:text-2xl font-bold text-xl mb-4 mt-3'>Day {day.day_number} - {day.destination_name}</p>

        <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 w-auto'>
          {day.activities.map((activity, activityIndex) => (
            <div key={activityIndex} className='flex'>
              {activity.description !== null ? 
                <div className='bg-white flex flex-col justify-top h-full w-full rounded-lg p-2 shadow-md'>
                  <div className='flex justify-between mb-5 gap-5'>
                    <p className='text-black font-semibold text-[12px] font-poppins'>{activity.name}</p>
                    <img src={baseUrl + activity.image} alt={activity.name} className='w-8' />
                  </div>
                  <div>
                    <p className='text-black/70 font-normal text-[12px] font-poppins'>{activity.description}</p>
                  </div>
                </div> 
              : 
                <div className='relative h-full w-full shadow-md'>
                  <img src={baseUrl + activity.image} alt={activity.name} className='h-full object-cover w-full rounded-lg' />
                  <div className='absolute inset-0 bg-black bg-opacity-30 rounded-lg'></div>
                  <div className='absolute bottom-0 left-0 p-2'>
                    <p className="text-white font-semibold font-poppins text-[12px] w-full">{activity.name}</p>
                  </div>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
      {/* end content section */}
    </div>
  ))}

  {/* whats include section */}
  <div className='h-28'></div>
  <div className='w-[95%] bg-white rounded-2xl m-5 p-12 mx-auto'>
    <p className='font-poppins font-bold text-left'>What’s include in this package</p>
    <p className='font-poppins text-left text-[#587893]'>Get full details of stay your transportation, flight or trains, other activities here</p>
    <div className='bg-[#F5F5F5] p-10 mt-8 rounded-lg'>
      <p className='font-poppins text-center text-[#1E75BB]'>Please complete your booking, then the system will confirm and update information for you</p>
    </div>
  </div>
  {/* end whats include section */}
</div>
{/* end timeline section */}










{/* number of travelers section */}
<div className="flex justify-center items-start px-5 order-first lg:order-last w-full">
        <div className="bg-white lg:p-10 p-6 rounded-lg shadow-lg w-full lg:w-auto">
          <p className='text-[#11A59B] font-bold text-xl font-poppins pb-4'>Choose No of Travellers</p>

          <div className='bg-[#11A59B] p-4 rounded-t-2xl py-5'>
  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Adults</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseAdult} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{selectedadultcount}</div>
      <div>
        <button onClick={increaseAdult} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>

  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Children (0 to 14 years)</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreasechildrencount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{childrencount}</div>
      <div>
        <button onClick={increasechildrencount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>

  

  <div className='flex justify-between items-center mb-12'>
    <div className='text-white font-poppins'>Age - Child 1</div>
    <div className='grid grid-cols-1'>
      <select className='border rounded p-2 w-full font-thin text-blue-500 font-poppins' onChange={handleChange} value={selectedAge}>
        <option className='text-center' value="" disabled>Age</option>
        {[...Array(15).keys()].map(i => {
          const age = i;
          return (
            <option key={age} value={age}>{age}</option>
          );
        })}
      </select>
    </div>
  </div>

  <div className='flex justify-between items-center'>
    <div className='text-white font-poppins'>Rooms</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseroomcount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>-</button>
      </div>
      <div className='text-center text-white font-poppins'>{roomcount}</div>
      <div>
        <button onClick={increaseroomcount} className='border-[1px] border-white px-2 font-poppins text-white rounded-xl'>+</button>
      </div>
    </div>
  </div>
</div>

{/* selected info section */}
<div className='bg-[#E4FFFF] py-8 rounded-b-2xl'>
<div className='grid grid-cols-3 sm:grid-cols-2 xl:grid-cols-3 justify-start w-[100%] px-4'>
<div className='text-[#1E75BB] font-bold font-poppins'>Rooms -  {roomcount}</div>
<div className='text-[#1E75BB]  font-poppins'>{selectedadultcount} Adults , </div>
<div className='text-[#1E75BB]  font-poppins'>{childrencount} child</div>
</div>
</div>

     {/* end selected info section */}


{/* price section */}
<div className='bg-[#AEF1EC] my-12 font-poppins p-5 rounded-xl'>
<p className='text-[#006464] font-bold'><b className='text-[#006464] text-4xl'>{popular.price_per_person}</b>/Person</p>
<p className='text-[#006464] py-2'>4 night, Activities & Other Amenities</p>
</div>
{/* end price section */}




{/* travel option section */}
<div>
  <p className='text-center font-poppins pb-6'>
    Would you like to arrange your <br/> travel with this package ?
  </p>

  <div className='grid lg:grid-cols-2 grid-cols-1 items-center mx-auto w-full justify-end text-center bg-[#F5F5F5] font-poppins rounded-lg py-12'>
    <div>
      <label className='text-[#11A59B] font-semibold'>
        <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
          type='radio'
          name='travelOption'
          value='flight'
          checked={travelOption === 'flight'}
          onChange={handleBookingOptionChange}
        /> BOOK WITH FLIGHT
      </label>
    </div>
    <div>
      <label className='text-[#11A59B] font-semibold'>
        <input className='appearance-none h-3 w-3 border border-gray-300 rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
          type='radio'
          name='travelOption'
          value='train'
          checked={travelOption === 'train'}
          onChange={handleBookingOptionChange}
        /> BOOK WITH TRAIN
      </label>
    </div>
  </div>

  {/* Center the button */}
  <div className='flex justify-center'>
    <button className='bg-[#11A59B] rounded-full px-20 py-5 -mt-8 text-white text-center' onClick={handleBooking}>
      BOOK PACKAGE
    </button>
  </div>
</div>
{/* travel option section end */}





        </div>
      </div>

{/* end number of travelers section */}








</div>
</div>











        </div>
        {/* end body section */}


{/* footer section */}
<Footer />
{/* end body section */}






    </div>
  )
}

export default PackageDetails
import React, { useState, useRef,useEffect } from 'react';
import plogo from '../assets/plogo.png';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slide1 from '../assets/phslide1.png';
import slide2 from '../assets/phslide2.png';
import slide3 from '../assets/phslide1.png';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';


const countryCodes = [
  { code: '+1', country: 'US' },
  { code: '+91', country: 'IN' },
  { code: '+44', country: 'UK' },
];

const  MobileInput = ({ onOtpSuccess }) => {

  const { setToken } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [otpfeedback, setOtpFeedBack] = useState(false);
  const [registereduser, setRegisteredUser] = useState()
  const [registrationid, setRegistrationId] = useState()
  const [startCountdown, setStartCountdown] = useState(false);
  const [Login,setLogin] = useState(false)
  const [count, setCount] = useState(30); // Initial count value
  const sliderRef = useRef(null);
  const images = [slide1, slide2, slide3];
  const overlayTexts = ["Peaceful vacation with Touris Mooze.", "Explore, the Unexplored places.", "Peaceful vacation with Touris Mooze."];
  const overlayParagraphs = [
    "Venture into unknown realms, uncover hidden treasures, embrace the thrill of discovery—explore the unexplored with wonder!",
    "Dive deep into the mysteries of nature, discover hidden gems, and create unforgettable memories in the lap of untouched beauty.",
    "Embark on a journey of tranquility, immerse yourself in the serene beauty of nature, and rejuvenate your soul with Touris Mooze."
  ];
console.log('login state:',Login)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots, currentSlide) => (
      <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 lg:px-12' style={{ bottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
        <ul className="flex justify-center xl:justify-start space-x-1 lg:m-0">{dots}</ul>
        <div className='text-white flex justify-center xl:justify-end items-center space-x-2 xl:-mt-8 m-2'>
          <div className='backdrop-blur-sm bg-white/30 flex justify-center lg:justify-end items-center space-x-2 px-5 lg:py-2 py-1 rounded-full'>
            <button onClick={() => sliderRef.current.slickPrev()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
              <span>&#x2190;</span> <span>PREV</span>
            </button>
            <span className="text-white">|</span>
            <button onClick={() => sliderRef.current.slickNext()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
              <span>NEXT</span> <span>&#x2192;</span>
            </button>
          </div>
        </div>
      </div>
    ),
    customPaging: i => (
      <button className="block w-2.5 h-2.5 bg-gray-300 rounded-full"></button>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [mobile, setMobile] = useState('');
  const [fullname, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [countryCode, setCountryCode] = useState('+91');
  // const [countryName, setCountryName] = useState('IN');
  const [otp, setOtp] = useState(new Array(4).fill(''));

  const handleChange = (e) => {
    setMobile(e.target.value);
  };

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };




  const handleCountryCodeChange = (event) => {
    const selectedCode = event.target.value;
    const selectedCountry = countryCodes.find(code => code.code === selectedCode);
    
    setCountryCode(selectedCode);
    // setCountryName(selectedCountry.country)
    if (selectedCountry) {
      console.log('Selected Country Code:', selectedCode);
      console.log('Selected Country Name:', selectedCountry.country);
    }
  };

  const handleOtpChange = (element, index) => {
    if (/^\d*$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      if (element.nextSibling && element.value) {
        element.nextSibling.focus();
      }
    }
  };











  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobile === "") {
      alert('Enter mobile number');
    } else {
      // const mobileNumber = countryCode + mobile;
      const formData = {
         mobile: mobile,
         phone_code:countryCode,
        //  country_code:countryName,
        };

      try {
        const response = await axios.post('https://admin.tourismooze.com/api/public/login', formData, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data.status === true) {
          sliderRef.current.slickNext();
          setOtpFeedBack(true);
          dispatch({ type: 'SET_LOGIN', payload: true })
          setStartCountdown(true)
          setCount(30)
        }
      } catch (error) {
        console.error('Error uploading the data', error);
        alert(error)
      }
    }
  };



// registration
const handleRegister = async (e) => {
  e.preventDefault();
  if (mobile === "") {
    alert('Enter mobile number');
  } else {
    // const mobileNumber = countryCode + mobile;
    const formData = {
      user_id:registrationid,
      name:fullname,
      email: email
    
    };

    try {
      const response = await axios.post('https://admin.tourismooze.com/api/public/register', formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      
      if (response.data.status === true) {
        sliderRef.current.slickNext();
        console.log("user token", response.data.data.token);
        setToken(response.data.data.token); // Set the token in context
        dispatch({ type: 'SET_LOGIN', payload: true })
        onOtpSuccess();

      }
      if(response.data.status === false) {
        alert(response.data.errors.email)
      }
    } catch (error) {
      console.error('Error uploading the data', error);
      alert(error)
    }
  }
};
// end registration









  useEffect(() => {
    if (startCountdown) {
      const interval = setInterval(() => {
        if (count > 0) {
          setCount(count - 1);
        } else {
          clearInterval(interval); // Stop the countdown when count reaches 0
        }
      }, 1000); // Update count every second

      return () => clearInterval(interval); // Cleanup function to clear interval on unmount
    }
  }, [startCountdown, count]); // Run effect when startCountdown or count changes









  const submitOtp = async (e) => {
    e.preventDefault();
    const enteredDigits = otp.filter(digit => digit !== "").length;
    if (enteredDigits < 4) {
      alert('Complete OTP field');
    } else {
      const otpData = { 
        otp: otp.join(''),
        mobile: mobile,
        phone_code:countryCode,
      }; 
        // Join OTP digits into a single string
      try {
        const otpresponse = await axios.post('https://admin.tourismooze.com/api/public/verify', otpData, {
          headers: { 'Content-Type': 'application/json' },
          
        });

        if (otpresponse.data.status === true && otpresponse.data.data.token === null ) {
          alert(otpresponse.data.message);
          setRegisteredUser(true)
          setRegistrationId(otpresponse.data.data.id)
          sliderRef.current.slickNext();
          console.log(otpresponse.data.message)
        } 
        if(otpresponse.data.status === true && otpresponse.data.data.token !== null){
          dispatch({ type: 'UPDATE_VALUE', payload: true })
            onOtpSuccess();
        }
        
        setOtpFeedBack(false)
        setToken(otpresponse.data.data.token);
        setOtp(new Array(4).fill(''))
        // alert(otpresponse.data.message)
      } catch (error) {
        alert(error);
        setOtpFeedBack(false)
      }
    }
  };

  return (
    <div className='grid grid-cols-2'>
      <div className="container mx-auto">
        <Slider {...settings} className='-mb-[8px]' ref={sliderRef}>
          {images.map((img, index) => (
            <div key={index} className="slider-item relative">
              <img src={img} alt={`Slide ${index + 1}`} className="w-full rounded-l-2xl" />
              <div className="overlay absolute inset-0 bg-black opacity-20 rounded-l-2xl"></div>
              <div className="content absolute inset-0 flex flex-col sm:justify-center justify-start mt-3 items-center px-1 lg:px-4 xl:-mb-40 2xl:-mb-60 -mb-0 pb-10">
                <p className="text-white text-md xl:text-4xl md:text-2xl text-center font-bold font-poppins mb-2">{overlayTexts[index]}</p>
                <p className="text-white text-[12px] sm:text-lg xl:text-md text-center font-normal font-poppins lg:pb-10">{overlayParagraphs[index]}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="flex flex-col items-center justify-center gap-2 lg:p-4 px-[6px]">
        <form onSubmit={otpfeedback ? submitOtp : handleSubmit} className="flex flex-col items-center space-y-2 lg:space-y-16 w-full max-w-sm">
          <div className="w-full flex h-24 md:h-auto justify-center">
            {/* <img src={otpfeedback && registereduser !== true  ? unlocked : plogo} alt="Logo" className="lg:w-full w-24 h-24 object-contain lg:h-28" /> */}

            <img src={plogo} className="sm:w-full w-28 h-28 object-contain " />
            
          </div>
          <div className="w-full">
            {registereduser !== true ?
                <label className="block mb-[1px] lg:mb-2 text-[#4C4C4C] font-poppins lg:text-xl text-[10px] font-semibold text-center" htmlFor="mobile">
                {otpfeedback ? "Confirm your phone number" : "Enter your phone number"}
              </label> : <div><label className="block mb-[1px] lg:mb-2 text-[#4C4C4C] font-poppins lg:text-xl text-[10px] font-semibold text-left md:text-center">More info needed</label>
              <p className='px-1 hidden md:block lg:text-sm text-[8px] lg:py-2 py-[2px] font-poppins text-[#0F75B7]'>{`We verified your number ${mobile}, Please fill some more basic details to proceed`}</p>
              <p className='px-1 md:hodden block lg:text-sm text-[8px] lg:py-2 py-[2px] font-poppins text-[#0F75B7]'>{`We verified your number ${mobile},`}</p>
              </div>
              
            }

            {otpfeedback && registereduser !== true ? (
              <div className='px-1'>
                <label className="block text-[#4C4C4C] font-poppins lg:text-xl text-[10px] font-semibold lg:text-left mb-[1px] lg:mb-2" htmlFor="otp">
                  Enter OTP
                </label>
                <div className="flex gap-[5px] md:gap-4 w-2">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={data}
                      onChange={e => handleOtpChange(e.target, index)}
                      onFocus={e => e.target.select()}
                      className="w-8 h-8 md:w-12 md:h-12 rounded-lg bg-[#F3F3F3] text-center md:text-xltext-sm font-bold focus:outline-none focus:shadow-outline text-gray-600"
                    />
                  ))}
                  
                </div>
              </div>
            ) : (
              <div className=" rounded-full flex">
                { registereduser !== true ?
               <select
               value={countryCode}
               onChange={handleCountryCodeChange}
               className="select-chevron lg:w-2/2 w-[45%] sm:w-[20%] lg:px-1 px-0 text-gray-500 text-[14px] md:text-md leading-tight focus:outline-none focus:shadow-outline bg-[#F3F3F3] lg:py-4 py-2 rounded-l-full"
             >
               {countryCodes.map((code) => (
                 <option key={code.code} value={code.code}>
                   {code.code}
                 </option>
               ))}
             </select> :''
                }

                {registereduser !== true ?
                 <input
                 type="text"
                 id="mobile"
                 name="mobile"
                 value={mobile}
                 onChange={handleChange}
                 className="appearance-none lg:w-3/4 w-full lg:py-2 py-1 my-1 lg:px-3 px-[2px] text-gray-700 bg-[#F3F3F3] text-[14px] md:text-md leading-tight focus:outline-none focus:shadow-outline rounded-r-3xl"
                 placeholder="Phone number"
               /> : 
               <div className='w-full gap-5'>
               <input
                     type="text"
                     id="mobile"
                     name="mobile"
                     value={fullname}
                     onChange={handleNameChange}
                     className="appearance-none lg:w-full w-full lg:py-3 my-2 py-1 lg:px-3 px-2 text-gray-700 bg-[#F3F3F3] leading-tight focus:outline-none focus:shadow-outline rounded-2xl text-[12px]"
                     placeholder="Your Full name"
                   />
                   <div className="relative lg:w-full w-full my-2 hidden">
                    <input
                          type="text"
                          id="mobile"
                          name="mobile"
                          value={mobile}
                          onChange={handleChange}
                          className="appearance-none lg:w-full w-full lg:py-3 py-1 lg:px-3 px-2 text-gray-400 bg-[#F3F3F3] leading-tight focus:outline-none focus:shadow-outline rounded-2xl pr-10"
                          placeholder="Phone number"
                          disabled
                        />
                        {/* <FaPen
        className="absolute right-3 top-1/2 transform -translate-y-1/2 w-3 h-3 text-gray-400 z-20"
        onClick={() => setOtpFeedBack({ otpfeedback: false })}
      /> */}
      
                      </div>
                   <input
                     type="text"
                     id="mobile"
                     name="mobile"
                     value={email}
                     onChange={handleEmailChange}
                     className="appearance-none lg:w-full w-full lg:py-3 py-1 lg:px-3 px-2 text-gray-700 bg-[#F3F3F3] leading-tight focus:outline-none focus:shadow-outline rounded-2xl text-[12px]"
                     placeholder="Email ID"
                   />
               </div>
                
              }
               
                
              </div>
            )}
            
            { registereduser !== true ?<div>
            <p className='px-1 lg:text-sm text-[8px] lg:py-2 py-[2px] font-poppins text-[#0F75B7]'>
              {otpfeedback ? `Enter the verification code sent to ${mobile}` : 'We will send an OTP for verification to your number'}
            </p>
            { otpfeedback ? <p className='px-1 lg:text-sm text-[8px] lg:py-2 py-[2px] font-poppins text-[#0F75B7]'>{count === 0 ? 'OTP invalid' : `resend code in: ${count} seconds`}{count === 0 ? <button className='ml-5' onClick={()=>{setOtpFeedBack(false);setOtp(new Array(4).fill(''))}}> Resend Now</button>: <button disabled className='ml-5'> Resend Now</button>}
            
            </p>:""}
            </div>:''}
           
          </div>



          <div className="w-full flex justify-center lg:mt-4 mt-2">
          {registereduser !== true ? <button
              type="submit"
              className="text-white font-poppins text-[12px] lg:text-md lg:font-semibold lg:py-4 py-1 lg:px-12 px-6 rounded-full focus:outline-none focus:shadow-outline tracking-widest bg-[#0FA499] w-full"
            >
              CONFIRM
            </button>:<button onClick={handleRegister}
              className="text-white font-poppins text-[12px] lg:text-md lg:font-semibold lg:py-4 py-1 lg:px-12 px-6 rounded-full focus:outline-none focus:shadow-outline tracking-widest bg-[#0FA499] w-full"
            >
              CONFIRM
            </button>}

           

            
          </div>
        </form>
      </div>
    </div>
  );
};

const PrevArrow = ({ onClick }) => (
  <button className='hidden' onClick={onClick}></button>
);

const NextArrow = ({ onClick }) => (
  <button className='hidden' onClick={onClick}></button>
);

export default MobileInput;

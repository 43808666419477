import React, { useEffect, useState, useRef } from 'react';
import bg from '../assets/BG.svg';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Footer from './Footer';
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';

function DestinationDetail() {
    const baseUrl = 'https://admin.tourismooze.com/storage/'; // Replace with your actual base URL
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const sliderRef = useRef(null);
    const secsliderRef = useRef(null);
    const [destinationAttractiondata, setDestinationAttractionData] = useState([]);
  
    const location = useLocation();
    const { destination } = location.state;

    const [sliderDataOne, setSliderDataOne] = useState([]);
    const [sliderDataTwo, setSliderDataTwo] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer`, // Assuming you have a token from context
                    'Content-Type': 'application/json',
                };
    
                const response = await axios.post(`https://admin.tourismooze.com/api/public/destination/${destination.id}`, { headers });
    
                const destinationData = response.data.data.destination;
                const destinationattractions = response.data.data.destination_attractions;
             
                const sliderimagesone = response.data.data.slider_1;
                const sliderimagestwo = response.data.data.slider_2;
                setSliderDataTwo(sliderimagestwo);
                setSliderDataOne(sliderimagesone);
                setData(destinationData);
                setDestinationAttractionData(destinationattractions);
                console.log("Full Data", destinationData);

                destinationData.forEach((destination, index) => {
                    console.log("Heading:", destination.destination_heading);
                });
                destinationattractions.forEach((attraction, index) => {
                    console.log("Attraction name:", attraction.name);
                })

            } catch (error) {
                setError(error);
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [destination.id]);

    const fetchnear = async (destination) => {
        try {
            const headers = {
                'Authorization': `Bearer`, // Assuming you have a token from context
                'Content-Type': 'application/json',
            };

            const response = await axios.post(`https://admin.tourismooze.com/api/public/destination/${destination.id}`, { headers });

            const destinationData = response.data.data.destination;
            const sliderimagesone = response.data.data.slider_1;
            const sliderimagestwo = response.data.data.slider_2;
            setSliderDataTwo(sliderimagestwo);
            setSliderDataOne(sliderimagesone);
            setData(destinationData);
            console.log("Full Data", destinationData);

            destinationData.forEach((destination, index) => {
                console.log("Heading:", destination.destination_heading);
            });
        } catch (error) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        appendDots: (dots) => (
            <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 lg:px-12' style={{ bottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                <ul className="flex justify-center xl:justify-start space-x-1 lg:m-0">{dots}</ul>
                <div className='text-white flex justify-center items-center space-x-2 xl:-mt-8 m-2'>
                    <div className='backdrop-blur-sm bg-white/30 flex justify-center items-center space-x-2 px-5 lg:py-2 py-1 rounded-full'>
                        <button onClick={() => sliderRef.current.slickPrev()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
                            <span>&#x2190;</span> <span>PREV</span>
                        </button>
                        <span className="text-white">|</span>
                        <button onClick={() => sliderRef.current.slickNext()} className='bg-transparent border-none flex items-center space-x-1 text-sm'>
                            <span>NEXT</span> <span>&#x2192;</span>
                        </button>
                    </div>
                </div>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const secondslidesettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        appendDots: (dots) => (
            <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 lg:px-12' style={{ bottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                <ul className="lg:hidden flex justify-center xl:justify-start space-x-1 lg:m-0">{dots}</ul>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div style={{ backgroundImage: `url(${bg})` }} className='bg-cover bg-center bg-no-repeat'>
            <div className='lg:w-[80%] w-full mx-auto my-8 bg-white p-8'>
                <button onClick={() => navigate(-1)} className="flex items-center my-2 focus:outline-none bg-[#1E75BB] rounded-md text-white font-poppins px-2 py-1">
                    <IoIosArrowBack className="mr-2 text-white" size={18} />Go Back
                </button>

                {/* slider section */}
                {loading && <div>Loading...</div>}
                {error && <div>Error: {error.message}</div>}
                {!loading && !error && (
                    <Slider ref={sliderRef} {...settings}>
                        {sliderDataOne.map((sliderimages, index) => (
                            <div key={index} className="relative dis mb-4 w-full rounded-lg overflow-hidden shadow-lg overflow-x-clip">
                                <img
                                    src={baseUrl + sliderimages.image_path}
                                    alt={destination.destination_name}
                                    className="w-full lg:h-96 h-72 object-cover overflow-x-clip"
                                />
                            </div>
                        ))}
                    </Slider>
                )}
                {/* end slider section */}

                {data.map((destination, index) => (
                    <div key={index}>
                        <p className='font-poppins text-[#006464] font-bold md:text-3xl text-2xl md:text-left text-center p-2'>{destination.destination_name}</p>
                        <div className='text-[#587893] font-poppins md:text-xl text-lg p-2 md:text-left text-center ' dangerouslySetInnerHTML={{ __html: destination.description_1 }} />
                        <div className='font-poppins text-black text-md md:text-xl p-2 md:text-left text-center' dangerouslySetInnerHTML={{ __html: destination.description_2 }} /><br /><br />
                    </div>
                ))}

                {/* second slider section */}
                {loading && <div>Loading...</div>}
                {error && <div>Error: {error.message}</div>}
                {!loading && !error && (
                    <Slider ref={secsliderRef} {...secondslidesettings}>
                        {sliderDataTwo.map((sliderimages, index) => (
                            <div key={index} className="relative dis mb-4 w-full rounded-lg overflow-hidden shadow-lg overflow-x-clip">
                                <img
                                    src={baseUrl + sliderimages.image_path}
                                    alt={destination.destination_name}
                                    className="w-full lg:h-96 h-72 object-cover overflow-x-clip"
                                />
                            </div>
                        ))}
                    </Slider>
                )}
                {/* end second slider section */}
                   
                {/* image grid info section */}
                <p className='font-poppins text-[#11A59B] font-semibold text-semibold my-2'>Nearby Destinations</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {data.map((destination, index) => (
                        <div key={index} className="border-[1px] border-[#11A59B] rounded-xl p-2 gap-4 cursor-pointer grid grid-cols-1 xl:grid-cols-2">
                            <div className="w-full h-48 overflow-hidden rounded-xl">
                                <img
                                    src={`${baseUrl}${destination.destination_image}`}
                                    alt={destination.destination_name}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="flex flex-col flex-grow">
                                <p className="text-left font-poppins text-[#006464] font-bold" dangerouslySetInnerHTML={{__html:destination.destination_name}}/>
                                <p className='font-poppins my-2 text-[12px] flex-grow overflow-y-scroll custom-scrollbar' style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    overflowY: 'auto', // Ensures scrolling
                                    scrollbarWidth: 'thin', // For Firefox
                                    scrollbarColor:'#11A59B',
                                    scrollBehavior: 'smooth' // Smooth scrolling
                                }} dangerouslySetInnerHTML={{__html:destination.description_2}}/>
                                <button className='font-poppins mt-auto text-[12px] self-end' onClick={() => fetchnear(destination)}>View</button>
                            </div>
                        </div>
                    ))}
                </div>
                {/* end grid info section */}

                {/* attractions section */}
                {destinationAttractiondata.length > 0 && (
                    <>
                        <p className='font-poppins text-[#11A59B] font-semibold text-semibold my-2'>Attractions</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {destinationAttractiondata.map((attraction, index) => (
                                <div key={index} className="relative cursor-pointer">
                                    <div className="w-full h-48 overflow-hidden rounded-xl">
                                        <img
                                            src={`${baseUrl}${attraction.image}`}
                                            alt={attraction.name}
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via-transparent to-transparent p-4">
                                            <p
                                                className="font-poppins text-white font-bold mb-1"
                                                dangerouslySetInnerHTML={{ __html: attraction.name }}
                                            />
                                            <p
                                                className='font-poppins text-[12px] text-white overflow-y-scroll custom-scrollbar'
                                                style={{
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflowY: 'auto',
                                                    scrollbarWidth: 'thin',
                                                    scrollBehavior: 'smooth'
                                                }}
                                                dangerouslySetInnerHTML={{ __html: attraction.description }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {/* end attractions section */}
            </div>

            {/* footer section */}
            <Footer />
            {/* end footer section */}
        </div>
    );
}

export default DestinationDetail;
